<template>
<ValidationObserver ref="addressDetails">

  <div class="form-group row">
    <label class="col-2 col-form-label text-right">
      <span v-if="!allowPostal">Search for a street address</span>
      <span v-if="allowPostal">Search for a street/postal address</span>
    </label>
    <div class="col-4">
      <v-autocomplete
          class="mt-0 pt-0 mb-0" color="white"
          label="Start typing the address (minimum 3 characters)"
          :items="addressCompletionsArray"
          v-model="addressEntity.address"
          :search-input.sync="search"
          @change="getAddressDetails"
          :item-text="getItemText"
          return-object
          :disabled="isReadOnly"
      ></v-autocomplete>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-2 col-form-label text-right">Address Line 1<span v-if="mandatory" class="text-danger">*</span></label>
    <div class="col-4">
      <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="addressEntity.streetAddress" v-slot="{ errors }">
        <input type="text" class="form-control" v-model="addressEntity.streetAddress" :disabled="isReadOnly" maxlength="255" />
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <label class="col-2 col-form-label text-right">City/Suburb<span v-if="mandatory" class="text-danger">*</span></label>
    <div class="col-4">
      <ValidationProvider :rules="mandatory ? 'required|max:255' : 'max:255'" name="addressEntity.suburb" v-slot="{ errors }">
        <input type="text" class="form-control" v-model="addressEntity.suburb" :disabled="isReadOnly" maxlength="255" />
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-2 col-form-label text-right">Postcode<span v-if="mandatory" class="text-danger">*</span></label>
    <div class="col-4">
      <ValidationProvider :rules="mandatory ? 'required|max:4|numeric' : 'max:4|numeric'" name="addressEntity.postcode" v-slot="{ errors }">
        <input type="text" class="form-control" v-model="addressEntity.postcode" :disabled="isReadOnly" maxlength="4" />
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <label class="col-2 col-form-label text-right">State<span v-if="mandatory" class="text-danger">*</span></label>
    <div class="col-4">
      <ValidationProvider :rules="mandatory ? 'required' : ''" name="addressEntity.state" v-slot="{ errors }">
        <b-form-select class="custom-select custom-select-md" v-model="addressEntity.state" name="addressEntity.state" :disabled="isReadOnly">
          <option value="">Select State</option>
          <option value="NSW">NSW</option>
          <option value="VIC">VIC</option>
          <option value="QLD">QLD</option>
          <option value="WA">WA</option>
          <option value="SA">SA</option>
          <option value="ACT">ACT</option>
          <option value="TAS">TAS</option>
          <option value="NT">NT</option>
        </b-form-select>
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>

</ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "AddressDetails",

  props: {
    addressEntity: Object,
    isReadOnly: Boolean,
    mandatory: Boolean,
    allowPostal: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      serverUrl: null,
      search: null,
      addressCompletionsArray: []
    };
  },

  watch: {
    search(searchString) {
      if (!searchString) {
        searchString = "";
      }
      if(searchString.length > 2) {
        this.searchAddress(searchString);
      }
    },
  },

  mounted() {
    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV !== 'production') {
      this.serverUrl = `http://localhost:3000/services`;
    }
  },

  methods: {
    getItemText: function (item) {
      return `${item.full_address}`;
    },

    searchAddress: function (searchString) {
      if(this.isReadOnly) {
        return;
      }

      ApiService.get(`${this.serverUrl}/address/search`,
          { params: {searchString: searchString}}
      )
        .then(({ data }) => {
          this.addressCompletionsArray = data.result;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAddressDetails: function () {
      if(this.isReadOnly) {
        return;
      }

      let addressId = null;
      if(this.addressEntity && this.addressEntity.address) {
        addressId = this.addressEntity.address.id;
      }

      if (addressId) {
        ApiService.get(`${this.serverUrl}/address/${addressId}`)
          .then(({ data }) => {
            let userSelectedAddress = data.result;

            this.$set(this.addressEntity, 'postcode', userSelectedAddress.postcode);
            this.$set(this.addressEntity, 'state', userSelectedAddress.state_territory);
            this.$set(this.addressEntity, 'streetAddress', userSelectedAddress.address_line_1 || "");
            this.$set(this.addressEntity, 'suburb', userSelectedAddress.locality_name);
            this.$set(this.addressEntity, 'country', "Australia");
            if (userSelectedAddress.address_line_2) {
              this.$set(this.addressEntity, 'streetAddress', `${this.addressEntity.streetAddress}, ${userSelectedAddress.address_line_2}`);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
