<template>
  <ValidationObserver ref="membershipDetailsForm">
    <div class="card card-custom">
      <div class="card-body container">

        <b-alert v-if="errorAlert" variant="warning" show>
          <div class="text-dark" v-html="errorAlert"></div>
        </b-alert>

        <div v-if="formData && isFormReadOnly">
          <b-alert v-if="isFormProcessable" variant="secondary" show>
            <div class="text-dark" v-html="'This form is in \'Submitted\' status, and is read-only.'"></div>
          </b-alert>
          <b-alert v-if="!isFormProcessable" variant="warning" show>
            <div class="text-dark" v-html="formSubmittedMessage"></div>
          </b-alert>
        </div>

        <b-spinner v-if="loadingComparisonData" variant="primary"></b-spinner>
        <b-card class="mb-5 mt-3" border-variant="danger" body-class="px-5 py-5" v-if="formData && comparisonData && isFormProcessable">
          <h3 class="font-weight-bold text-dark">
            <i class="fa fa-exclamation-circle text-warning mr-2"></i>
            Comparison of Member's Current Data vs Form Data
          </h3>

          <table class="table table-responsive table-bordered mt-5">
            <thead>
            <th width="10%" class="text-center"></th>
            <th width="45%" class="text-center">CRM Data</th>
            <th width="45%" class="text-center">Form Data</th>
            </thead>
            <tr v-for="(cData, index) in comparisonData" :key="index">
              <td>{{ cData.label || cData.key }}</td>
              <td><span v-html="cData.crmData"></span></td>
              <td><span v-html="cData.formData"></span></td>
            </tr>
          </table>
        </b-card>

        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">
          <h3 class="font-weight-bold text-dark">
            <i class="fas fa-building text-primary mr-2"></i>
            Member Details
          </h3>

          <div class="form-group row">
            <label class="col-2 col-form-label text-right">Membership Number</label>
            <label class="col-4 col-form-label font-weight-bold">
              {{ formData.member.Membership_No }}
            </label>
            <label class="col-2 col-form-label text-right">ABN</label>
            <label class="col-4 col-form-label font-weight-bold">
              {{ formData.member.ABN_No }}
            </label>
          </div>

          <div class="form-group row">
            <label class="col-2 col-form-label text-right">Trading Name</label>
            <label class="col-4 col-form-label font-weight-bold">
              {{ formData.member.Account_Name }}
            </label>
            <label class="col-2 col-form-label text-right">Business Phone<span class="text-danger">*</span></label>
            <div class="col-3">
              <ValidationProvider :rules="{ required: true, 'phone-regex': phoneRegex, max: 15 }" name="phone" v-slot="{ errors }">
                <input type="text" class="form-control" v-model="formData.member.Phone" maxlength="15" :disabled="isFormReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-2 col-form-label text-right">Website</label>
            <div class="col-4">
              <ValidationProvider rules="max:255" name="website" v-slot="{ errors }">
                <input type="url" class="form-control" v-model="formData.member.Website" maxlength="255" :disabled="isFormReadOnly"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <!-- Business Address -->
          <b-card class="mb-3 mt-3" body-class="px-5 py-5">
            <div class="font-weight-bold text-dark">
              <i class="fas fa-map-marker-alt fa-sm text-primary mr-1"></i>
              Business Address
            </div>
            <address-details :address-entity="formData.member.address" :mandatory="true" :is-read-only="isFormReadOnly"></address-details>
          </b-card>

          <!-- Postal Address -->
          <b-card class="mb-3 mt-3" body-class="px-5 py-5">
            <div class="font-weight-bold text-dark">
              <i class="fas fa-envelope fa-sm text-primary mr-1"></i>
              Postal Address
            </div>
            <div class="form-group row mb-0">
              <label class="col-form-label ml-4 mr-4">
                Is your postal address same as the street address?<span class="text-danger">*</span>
              </label>

              <b-form-group class="mt-3">
                <ValidationProvider rules="required" name="postalAddressSame" v-slot="{ errors }">
                  <b-form-radio-group type="radio" v-model="formData.member.address.isPostalAddressSame" :disabled="isFormReadOnly">
                    <b-form-radio :value="true">Yes</b-form-radio>
                    <b-form-radio :value="false">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div v-if="formData.member.address.isPostalAddressSame === false">
              <address-details :address-entity="formData.member.postalAddress" :mandatory="true" :allow-postal="true"
                               :is-read-only="isFormReadOnly"></address-details>
            </div>
          </b-card>
        </b-card>

        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">
          <div class="d-flex justify-content-between">
            <h3 class="font-weight-bold text-dark">
              <i class="fas fa-user-tie text-primary mr-2"></i>
              Nominated Representative
            </h3>
          </div>
          <b-card-text>
            The nominated representative should be the primary account holder of MTA membership, responsible for
            providing authority for other staff to act on behalf of the business, make purchasing decisions,
            and updating information. This is usually the business owner or a member of management.
          </b-card-text>

          <table class="table table-hover table-bordered mt-5 mb-5" v-if="nominatedRep">
            <thead>
              <th width="14%" class="text-center">First Name</th>
              <th width="14%" class="text-center">Last Name</th>
              <th width="14%" class="text-center">Preferred Name</th>
              <th width="14%" class="text-center">Title/Role</th>
              <th width="14%" class="text-center">Email</th>
              <th width="14%" class="text-center">Phone</th>
              <th width="14%" class="text-center">Mobile</th>
            </thead>
            <tr>
              <td> {{ nominatedRep.First_Name }} </td>
              <td> {{ nominatedRep.Last_Name }} </td>
              <td> {{ nominatedRep.Preferred_Name }} </td>
              <td> {{ nominatedRep.Contact_Title }} </td>
              <td> {{ nominatedRep.Email }} </td>
              <td> {{ nominatedRep.Phone }} </td>
              <td> {{ nominatedRep.Mobile }} </td>
            </tr>
          </table>

          <div class="text-danger font-weight-bold font-size-h6" v-if="this.formData.newNominatedRep && this.formData.newNominatedRep.firstName">
            Update requested for Nominated Representative details - {{ this.formData.newNominatedRep.firstName }} {{ this.formData.newNominatedRep.lastName }}.
            <span v-if="isFormProcessable"> CRM task will be created after the form is accepted.</span>
            <span v-else>This change will be processed within 2 business days.</span>
          </div>
          <button v-if="!isFormReadOnly" class="btn btn-outline-primary mt-2"
                  @click.prevent="openNominatedRepChangeDialog()" >
            Update Nominated Rep Details
          </button>

          <b-modal ref="changeNominatedRepDialog" size="lg" no-close-on-esc no-close-on-backdrop hide-header-close
                   @ok="saveNewNominatedRep" @cancel="clearNewNominatedRep"
          >
            <template #modal-header>
              <h5>Update Nominated Rep Details</h5>
              <b-button size="sm" variant="secondary" @click="tempNominatedRep = {}">
                Clear All Data
              </b-button>
            </template>

            <ValidationObserver ref="changeNominatedRepForm">
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">First Name<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider rules="required|max:255" name="tempNominatedRep.firstName" v-slot="{ errors }">
                    <input type="text" class="form-control" v-model="tempNominatedRep.firstName" maxlength="255"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">Last Name<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider rules="required|max:255" name="tempNominatedRep.lastName" v-slot="{ errors }">
                    <input type="text" class="form-control" v-model="tempNominatedRep.lastName" maxlength="255"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">Preferred Name<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider rules="required|max:255" name="tempNominatedRep.preferredName" v-slot="{ errors }">
                    <input type="text" class="form-control" v-model="tempNominatedRep.preferredName" maxlength="255"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">Title/Role<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider rules="required" name="tempNominatedRep.title" v-slot="{ errors }">
                    <b-form-select class="custom-select custom-select-md" v-model="tempNominatedRep.title">
                      <option value=""></option>
                      <option value="Accounts">Accounts</option>
                      <option value="Admin">Admin</option>
                      <option value="HR">HR</option>
                      <option value="Payroll">Payroll</option>
                      <option value="Legal">Legal</option>
                      <option value="Owner/General Manager/Principle">Owner/General Manager/Principle</option>
                      <option value="Sales">Sales</option>
                      <option value="Service/Parts">Service/Parts</option>
                    </b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">Email<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider rules="required|valid-email|max:100" name="tempNominatedRep.email" v-slot="{ errors }">
                    <input type="email" class="form-control" maxlength="100" v-model="tempNominatedRep.email" />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">Phone<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider :rules="{ required: true, 'phone-regex': phoneRegex, max: 15 }" name="tempNominatedRep.phone" v-slot="{ errors }">
                    <input type="text" class="form-control" maxlength="15" v-model="tempNominatedRep.phone"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">Mobile<span class="text-danger">*</span></label>
                <div class="col-4">
                  <ValidationProvider :rules="{ required: true, 'phone-regex': phoneRegex, max: 15 }" name="tempNominatedRep.mobile" v-slot="{ errors }">
                    <input type="text" class="form-control" maxlength="15" v-model="tempNominatedRep.mobile"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">Comments<span class="text-danger">*</span></label>
                <div class="col-10">
                  <ValidationProvider rules="required|max:2000" name="tempNominatedRep.comments" v-slot="{ errors }">
                    <textarea class="form-control" maxlength="2000" v-model="tempNominatedRep.comments"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>
          </b-modal>

        </b-card>

        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">
          <h3 class="font-weight-bold text-dark">
            <i class="fas fa-user-friends text-primary mr-2"></i>
            Employee Relations Approved Contacts
          </h3>

          <b-card-text>
            The contacts you nominate in this section will have authority to seek advice from MTA employment relations
            and legal advisors. These should be business owners, HR managers, Payroll Officers or the person responsible
            for HR in your business.
          </b-card-text>

          <table class="table table-responsive table-bordered mt-5">
            <thead>
              <th width="14%" class="text-center">First Name<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Last Name<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Preferred Name<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Title/Role<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Email<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Phone<span class="text-danger">*</span></th>
              <th width="14%" class="text-center">Mobile<span class="text-danger">*</span></th>
              <th width="2%" class="text-center"></th>
            </thead>
            <tr v-for="(erContact, index) in formData.erContacts" :key="index">
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.First_Name }}</span>
                <span v-else>
                  <ValidationProvider rules="required" :name="`erContact${index}.firstName`" v-slot="{ errors }">
                    <input type="text" maxlength="255" class="form-control" v-model="erContact.First_Name" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Last_Name }}</span>
                <span v-else>
                  <ValidationProvider rules="required" :name="`erContact${index}.lastName`" v-slot="{ errors }">
                    <input type="text" maxlength="255" class="form-control" v-model="erContact.Last_Name" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Preferred_Name }}</span>
                <span v-else>
                  <ValidationProvider rules="required" :name="`erContact${index}.preferredName`" v-slot="{ errors }">
                    <input type="text" maxlength="255" class="form-control" v-model="erContact.Preferred_Name" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Contact_Title }}</span>
                <span v-else>
                  <ValidationProvider rules="required" :name="`erContact${index}.title`" v-slot="{ errors }">
                    <b-form-select class="custom-select custom-select-md" v-model="erContact.Contact_Title" :disabled="isFormReadOnly">
                      <option value=""></option>
                      <option value="Accounts">Accounts</option>
                      <option value="Admin">Admin</option>
                      <option value="HR">HR</option>
                      <option value="Payroll">Payroll</option>
                      <option value="Legal">Legal</option>
                      <option value="Owner/General Manager/Principle">Owner/General Manager/Principle</option>
                      <option value="Sales">Sales</option>
                      <option value="Service/Parts">Service/Parts</option>
                    </b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Email }}</span>
                <span v-else>
                  <ValidationProvider rules="required|valid-email|max:100" :name="`erContact${index}.email`" v-slot="{ errors }">
                    <input type="email" maxlength="100" class="form-control" v-model="erContact.Email" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>

              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Phone }}</span>
                <span v-else>
                  <ValidationProvider :rules="{ required: true, 'phone-regex': phoneRegex, max: 15 }" :name="`erContact${index}.phone`" v-slot="{ errors }">
                    <input type="text" maxlength="15" class="form-control" v-model="erContact.Phone" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <span v-if="isNominatedRep(erContact.id)">{{ erContact.Mobile }}</span>
                <span v-else>
                  <ValidationProvider :rules="{ required: true, 'phone-regex': phoneRegex, max: 15 }" :name="`erContact${index}.mobile`" v-slot="{ errors }">
                    <input type="text" maxlength="15" class="form-control" v-model="erContact.Mobile" :disabled="isFormReadOnly"/>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </td>
              <td>
                <button class="btn btn-sm btn-outline-danger" @click.prevent="deleteErContact(index)" title="Delete"
                        v-if="!isFormReadOnly && !isNominatedRep(erContact.id)">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </table>
          <button class="btn btn-primary mt-2" @click.prevent="addErContact()" v-if="!isFormReadOnly">
            Add ER Contact
          </button>
        </b-card>

        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">
          <h3 class="font-weight-bold">
            <i class="fas fa-book text-primary mr-2"></i>
            Member Offer
          </h3>

          <div class="row col-12 mt-5">
            <label class="mr-5 font-size-h6">
              Would you like a Complimentary Diary for 2023?<span class="text-danger">*</span>
            </label>

            <b-form-group class="mb-0">
              <ValidationProvider rules="required" name="Opted_for_Diary_Pack" v-slot="{ errors }">
                <b-form-radio-group type="radio" v-model="formData.member.Opted_for_Diary_Pack" :disabled="isFormReadOnly">
                  <b-form-radio value="Yes"><span class="font-size-h6">Yes</span></b-form-radio>
                  <b-form-radio value="No"><span class="font-size-h6">No</span></b-form-radio>
                </b-form-radio-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </div>
        </b-card>

<!--        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">-->
<!--          <h3 class="font-weight-bold">-->
<!--            <i class="fas fa-redo-alt text-primary mr-2"></i>-->
<!--            Auto Renewal-->
<!--          </h3>-->
<!--          <b-card-text>-->
<!--            More time, less hassle. Setting your membership to auto-renew means your membership will always stay active.-->
<!--            Just like any other subscription, you can opt out at any time and we will always let you know if membership-->
<!--            fees are going to change in advance.-->
<!--          </b-card-text>-->

<!--          <div class="row col-12 mt-5">-->
<!--            <label class="mr-5 font-size-h6">-->
<!--              Would you like to opt-in for auto-renewal of your membership?<span class="text-danger">*</span>-->
<!--            </label>-->

<!--            <b-form-group class="mb-0">-->
<!--              <ValidationProvider rules="required" name="Opted_for_Auto_Renew" v-slot="{ errors }">-->
<!--                <b-form-radio-group v-model="formData.member.Opted_for_Auto_Renew" :disabled="isPageReadOnly">-->
<!--                  <b-form-radio value="Yes"><span class="font-size-h6">Yes</span></b-form-radio>-->
<!--                  <b-form-radio value="No"><span class="font-size-h6">No</span></b-form-radio>-->
<!--                </b-form-radio-group>-->
<!--                <span class="text-danger">{{ errors[0] }}</span>-->
<!--              </ValidationProvider>-->
<!--            </b-form-group>-->
<!--          </div>-->

<!--          <b-card v-if="formData.member.Opted_for_Auto_Renew === 'Yes'" class="mb-3 mt-3" body-class="px-5 py-5" style="background-color: lightblue">-->
<!--            Thank you for choosing to auto-renew your membership. We are currently configuring new payment options,-->
<!--            and will contact you soon to get your payment details. Stay tuned!-->
<!--          </b-card>-->
<!--        </b-card>-->

        <b-card class="mb-5 mt-3" border-variant="primary" body-class="px-5 py-5" v-if="formData">
          <h3 class="font-weight-bold">
            <i class="fas fa-file-signature text-primary mr-2"></i>
            Declaration
          </h3>
          <div class="mt-5 font-size-h6">
            I/We declare that the information above is true and correct. I/We declare that my/our nominated
            representative is a Principal or Proprietor of the firm or partnership or a Director, Secretory or Manager
            of the company or corporation, or an Executive Officer of the unincorporated body which is the Member of
            the Association.
          </div>

          <div class="mt-5 font-weight-bold font-size-h5">Person signing this declaration - {{ declarationSigner }} (Nominated Rep)</div>

          <div class="form-group row mb-1">
            <div class="col-6">
              <label class="col-form-label text-right">
                <span class="font-weight-bold font-size-h6">
                  Signature<span class="text-danger">*</span>
                </span>
              </label>
              <div v-if="!isFormReadOnly">
                <VueSignaturePad width="500px" height="200px" :customStyle="signaturePadCustomStyle"
                                 ref="formSignaturePad" :options="{ onBegin }" />
                <div v-if="formData.signatureData && formData.signatureData.isEmpty === true">
                  <span class="text-danger">Signature is required</span>
                </div>
                <div>
                  <button v-if="!isFormReadOnly" class="btn btn-secondary btn-sm mt-2" @click.prevent="clearSignature()">
                    Clear Signature
                  </button>
                </div>
              </div>
              <div v-if="isFormReadOnly && hasSignatureData">
                <img :src="formData.signatureData.data" width="500px" height="200px" :class="signaturePadCustomStyle"/>
              </div>
            </div>
          </div>
        </b-card>

        <div class="d-flex justify-content-center border-top pt-5" v-if="formData">
          <button class="btn btn-success font-weight-bold text-uppercase px-5 py-4"
                  @click.prevent="submitForm" v-if="!isFormReadOnly">
            Submit Form
          </button>

          <div v-if="isFormProcessable">
            <button class="btn btn-success font-weight-bold text-uppercase px-5 py-4"
                    @click.prevent="acceptForm">
              Accept Form
            </button>
            <button class="ml-5 btn btn-danger font-weight-bold text-uppercase px-5 py-4"
                    @click.prevent="openUnlockFormDialog">
              Unlock Form
            </button>
          </div>

        </div>

      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import _ from "lodash";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import AddressDetails from "@/view/pages/wizard/membership-details-form/AddressDetails";
import Swal from "sweetalert2";

export default {
  name: "MembershipDetailsForm",

  components: { AddressDetails },

  data() {
    return {
      serverUrl: null,
      formId: null,
      userId: null,
      formData: null,
      comparisonData: null,
      loadingComparisonData: false,
      errorAlert: null,

      tempNominatedRep: null
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    let queryParams = this.$route.query;
    if (!queryParams.formId || (queryParams.userId && !queryParams.token)) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.formId = queryParams.formId;
    this.userId = queryParams.userId || null;
    ApiService.saveTokenAndSetHeader(queryParams.token);

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV !== 'production') {
      this.serverUrl = `http://localhost:3000/services`;
    }

    // Get form data
    this.getFormData();
  },

  computed: {
    isFormReadOnly: function() {
      if(this.formData) {
        return this.formData.K_FormStatus !== 'Created' && this.formData.K_FormStatus !== 'In Progress'
            && this.formData.K_FormStatus !== 'Incomplete';
      }
      return true;
    },

    isFormProcessable: function() {
      if(this.formData) {
        return this.formData.K_FormStatus === 'Submitted' && this.userId && this.$route.query.token;
      }
      return false;
    },

    phoneRegex: function () {
      return /^[\d+][\d ]+/;
    },

    nominatedRep: function() {
      let erContacts = _.get(this.formData, 'erContacts');
      if(Array.isArray(erContacts) && erContacts.length > 0) {
        return _.find(erContacts, { 'Nominated_Rep': true });
      }
      return null;
    },

    declarationSigner: function () {
      if(this.formData) {
        if(this.formData.newNominatedRep && this.formData.newNominatedRep.firstName) {
          return `${this.formData.newNominatedRep.firstName} ${this.formData.newNominatedRep.lastName}`
        }
        else {
          return `${this.nominatedRep.First_Name} ${this.nominatedRep.Last_Name}`;
        }
      }
      return null;
    },

    signaturePadCustomStyle: function () {
      return { border :"gray 1px solid" };
    },

    hasSignatureData: function() {
      let signatureData = _.get(this.formData, 'signatureData.data');
      return signatureData && String(signatureData).startsWith('data:image/png;base64');
    },

    formSubmittedMessage: function() {
      return `This form cannot be modified. If you wish to make a change to your information, please send an email to
              <a href="mailto:membership@mtansw.com.au">membership@mtansw.com.au</a>`;
    }
  },

  methods: {

    getFormData: function () {
      if(!this.formId) {
        throw `Invalid form ID!`
      }

      ApiService.get(`${this.serverUrl}/md-forms/${this.formId}`)
        .then(({ data }) => {
          this.formData = data.result;

          // Set wizard title and subtitle
          this.$store.commit("setWizardTitle", `Membership Profile & Preferences`);
          this.$store.commit("setWizardSubtitle", this.formData.member.Account_Name);

          // Get comparison data if the form is processable.
          this.getComparisonData();

          this.stopPageLoading();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    addErContact: function () {
      this.formData.erContacts.push({});
    },

    deleteErContact: function (index) {
      this.formData.erContacts.splice(index, 1);
    },

    isNominatedRep: function (contactId) {
      return this.nominatedRep && contactId === this.nominatedRep.id;
    },

    openNominatedRepChangeDialog: function () {
      if(this.formData.newNominatedRep) {
        this.tempNominatedRep = _.cloneDeep(this.formData.newNominatedRep);
      }
      else if(this.nominatedRep) {
        this.tempNominatedRep = {
          firstName: this.nominatedRep.First_Name,
          lastName: this.nominatedRep.Last_Name,
          preferredName: this.nominatedRep.Preferred_Name,
          title: this.nominatedRep.Contact_Title,
          email: this.nominatedRep.Email,
          phone: this.nominatedRep.Phone,
          mobile: this.nominatedRep.Mobile
        };
      }
      else {
        this.tempNominatedRep = {};
      }
      this.$refs.changeNominatedRepDialog.show();
    },

    saveNewNominatedRep: function (bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.changeNominatedRepForm.validate()
        .then((isValid) => {
          if(isValid) {
            this.$set(this.formData, 'newNominatedRep', this.tempNominatedRep);
            this.tempNominatedRep = null;

            this.$refs.changeNominatedRepDialog.hide();
          }
        });
    },

    clearNewNominatedRep: function () {
      this.tempNominatedRep = null;
    },

    onBegin: function() {
      this.$refs.formSignaturePad.resizeCanvas();
    },

    clearSignature: function() {
      this.$refs.formSignaturePad.clearSignature();
    },

    submitForm: async function () {
      this.clearError();

      // Validate the form
      let isFormValid = await this.$refs.membershipDetailsForm.validate();

      // Validate the signature data
      this.formData.signatureData = this.$refs.formSignaturePad.saveSignature();

      // Stop the form submission if the last page and/or signature is not valid.
      if(!isFormValid || !this.formData.signatureData || this.formData.signatureData.isEmpty === true){
        this.setError('Please complete all fields on this page.');
        return;
      }

      this.startPageLoading();
      ApiService.post(`${this.serverUrl}/md-forms/${this.formId}/submit`, this.formData)
          .then(() => {
            this.stopPageLoading();

            Swal.fire({
              titleText: "Form Submission Successful",
              html: `<p>Thank you for your submission. Your information will be updated in our system within 2 business days.
               If you wish to make a change to your information, please send an email to <a href="mailto:membership@mtansw.com.au">membership@mtansw.com.au</a></p>
               <br/><span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              width: "40rem",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showCancelButton: false,
              showCloseButton: false,
              showConfirmButton: false
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    acceptForm: async function () {
      this.clearError();
      if(!this.isFormProcessable) {
        return;
      }

      this.startPageLoading();
      ApiService.post(`${this.serverUrl}/md-forms/${this.formId}/accept`, null, { params: {userId: this.userId}})
          .then(() => {
            this.stopPageLoading();

            Swal.fire({
              titleText: "Form Accepted",
              html: `<p>CRM member and contacts information has been updated.<br/> Please check the member record for any outstanding tasks.</p>
               <br/><span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              width: "40rem",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showCancelButton: false,
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: `Go to Member Record`
            })
              .then(result => {
                if(result.isConfirmed) {
                  this.goToMemberRecord();
                }
              });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    goToMemberRecord: function() {
      let link = document.createElement("a");
      link.hidden = true;
      link.id = 'memberRecordLink';
      link.target = '_parent';
      link.href = `https://crm.zoho.com.au/crm/tab/Accounts/${this.formData.member.id}`;

      document.body.appendChild(link);
      document.getElementById('memberRecordLink').click();
    },

    getComparisonData: function () {
      if(!this.isFormProcessable) {
        return;
      }

      this.loadingComparisonData = true;
      ApiService.get(`${this.serverUrl}/md-forms/${this.formId}/compare`)
          .then(({ data }) => {
            this.comparisonData = data.result;
            if(Array.isArray(this.comparisonData)) {
              this.comparisonData.forEach(cData => {
                if(cData.crmData) {
                  cData.crmData = cData.crmData.replace(/\n/g, '<br/>');
                }
                if(cData.formData) {
                  cData.formData = cData.formData.replace(/\n/g, '<br/>');
                }
              })
            }

            this.loadingComparisonData = false;
          })
          .catch((error) => {
            this.loadingComparisonData = false;
            this.handleError(error);
          });
    },

    openUnlockFormDialog: function() {
      Swal.fire({
        titleText: "Unlock Form",
        html: `Are you sure you want to unlock this form?`,
        icon: "warning",
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.unlockForm();
          }
        });
    },

    unlockForm: function () {
      this.startPageLoading();
      ApiService.post(`${this.serverUrl}/md-forms/${this.formId}/unlock`)
        .then(() => {
          this.stopPageLoading();

          Swal.fire({
            titleText: "Form Unlocked",
            html: `Please notify the member to complete the form again.`,
            icon: "info",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: `Go to Member Record`
        })
          .then(result => {
            if(result.isConfirmed) {
              this.goToMemberRecord();
            }
          });

    })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
